import {customElement, bindable, inject} from "aurelia-framework";
import {ArtificialIntelligence} from "../artificial-intelligence/helper/artificial-intelligence";
import {FlashService} from "../flash/flash-service";
import i18next from "i18next";
import {UserClient} from "../api/user-client";

@customElement('sio-textarea')
@inject(
    ArtificialIntelligence,
    FlashService,
    UserClient,
)
export class Textarea {
    @bindable field = null;
    @bindable({defaultBindingMode: 2}) value = null;

    constructor(
        artificialIntelligence,
        flash,
        userClient,
    ) {
        this.artificialIntelligence = artificialIntelligence;
        this.flash = flash;

        this.isArtificialIntelligenceEnabled = (userClient?.getUser()?.
            instance?.settings?.artificialIntelligenceInstanceSettings?.isActive) ?? false;
    }

    async openArtificialIntelligenceDialog(context) {
        const {id, modelId} = context?.parent?.contextObjectRef ?? {};

        try {
            const res = await this.artificialIntelligence.generate({id: id, modelId: modelId});

            if (this.artificialIntelligence.validateResponseObj(res)) {
                return;
            }

            this.field.value = res.response;
        } catch (error) {
            console.error(error);
            this.flash.error(error?.localizedMessage ?? error?.message ?? error ?? "Error");
        }
    }

    fieldChanged() {
        this.rows = this.field.rows ?? this.field.options?.rows ?? 5
    }
}
